( function ( $ ) {
	
	$( document ).ready( function() {
		
		$( document ).foundation();
		new WOW().init();		
		
		$( '<img/>', { 'id': 'preload-1', 'src': aftech.img_dir + 'menu-pointer.svg', 'class': 'preload' } ).hide().appendTo( 'body' );
		
	} );

} )( jQuery );
