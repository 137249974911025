( function ( $ ) {
	
	function sticky_footer() {
		
		var win_height = $( window ).height();
		var body_height = $( 'body' ).height();
		var footer = $( '#footer-widgets' );
		
		if ( win_height > body_height ) {
			
			var footer_height = footer.outerHeight();
			var margin_top = Math.floor( ( win_height - body_height ) - footer_height );
			
			footer.css( 'marginTop', margin_top );
			
		} else {
			
			footer.css( 'marginTop', 'auto' );
			
		}
		
	}
	
	$( window ).resize( sticky_footer );
	$( document ).ready( sticky_footer );

} )( jQuery );
